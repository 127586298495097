import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/felix/OneDrive/Documents/Bertram.Solutions/Website.TTcom/GatsbyJS-2022/src/components/mdx/component.js";
import { PortfolioPage, Summary, Review, Aside, Notes } from "../../../components/mdx/portfolio.js";
import { Border } from "../../../components/widgets/container";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = PortfolioPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{props.pageContext.frontmatter.author} {props.pageContext.frontmatter.title}</h1>
    <Summary mdxType="Summary">
      <Aside mdxType="Aside">
        <p><strong parentName="p">{` Similar Strategies `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-all-stars-tr/"
            }}>{`All-Stars Total Return`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/tt-mean-kitty"
            }}>{`Mean-Kitty`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/portfolios/connors-tps/"
            }}>{`Connors' TPS`}</a></li>
        </ul>
      </Aside>
      <ul>
        <li parentName="ul">{`Objective: aggressive growth with contrarian exposure`}</li>
        <li parentName="ul">{`Type: mean-reversion strategy`}</li>
        <li parentName="ul">{`Invests in: individual stocks, bond ETFs`}</li>
        <li parentName="ul">{`Rebalancing schedule: weekly, w/ daily position-sizing`}</li>
        <li parentName="ul">{`Taxation: 100% short-term capital gains`}</li>
        <li parentName="ul">{`Minimum account size: $30,000`}</li>
      </ul>
      <p>{`TuringTrader’s `}<em parentName="p">{`Quick-Change`}</em>{` aims to match the broad stock market returns but at significantly lower volatility. The strategy trades the mean-reversion of individual stocks to achieve this objective. Consequently, the strategy’s holding periods are very short, and investors must follow the rebalancing schedule closely. `}<em parentName="p">{`Quick-Change`}</em>{` appeals to investors seeking a diversifying component to smooth out returns and dampen volatility without adding unnecessary drag to their portfolio’s performance.`}</p>
    </Summary>
    <Review mdxType="Review">
      <h2>{`Strategy Rules & Schedule`}</h2>
      <Aside mdxType="Aside">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "512px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAE3klEQVQ4y2VUWWyUVRS+0iclvhgSeSAQjFAopWWVJeDGYgqiDxIMZakiRkz0wQVZhAiVLcGWgAoFElpppzNTpmWGLpS2086/3HPvv8zSzkwLEqG+woviE/2n95jzUwyJD+ffcu73f+d83zlMSsEcR7J79zKMc14kBDBNM5kQsBQAjgJAFAAMANABIAIA+6WEEsPglMMAoIjyEZFZlmA+WDptM9Pkkzj3k8oBoBsAkIJzjob5vygYJg/ejJszXFswW0LR72nB6upijI2ODj4L9jEAjBMIACjOuScEeBlXFCbCmwikGErKv1KOqEjagrk2FOm6yfwynwHDCbAxzrkSAlQ8Yarz4X51IdyvfgnF1c/BOJ5rjqufGvvGapv6sC9hYsoR69Ku8MunCwMB5ZzzcZNzNDkv0F03uEo7Qp1q6EX25m/I1jYi29CEbGNATXq/Wb28LYxLvmzzot06WoI/7B0wpgIQQ8NkAqDbloCOBWOO5d8VvQ8lJbZ0arh2XxQ/r+3CVz+5huv2RTGeMLF3wPRzpIAx27ZRCFEnpWTMkrCUGLX36Cp6S1fRbl1d79aR/hzp0rD1poaWAPxz2MZFX7Tilup2/CNn+a2htnAAFQyGUNO0R0NDmWlsJGMdbYwlkFU0eWxDk3ruvQAFsk0BfHFLEKduD2N924AvwpTKMG4+0o75tMSEZqDruiocbsGy8iWFUCiMd+6MVLGMK6LhDg2nf9TirfkuqmbuuobzPovg+VA/NsUSVJrPkMp8YXMQP/zxCaCmmwSIuz/do6bPmOXV1JzBfD53jkQxBQAdKtzNWqriYAzX7Y/h/byN1CNS3rUAW7s0ZO8GsPJYB2aTAi3bwYaGqzi3ZIGaXVzqHTr0A+Zy2RABGtQP0+SFpC1U6Z4Ivr036peoG0+MnHYEXmkdQLa+EXee7MRcSiAXEmOxG3j8xCm1bPlq78DBw5jP55sJMDLRYI/UJTuQkqTw08mg5xP1vchWNvgMn/YwlUqqnp5eNb9ssVdTewZHRoZrCHA/AUoBHvXp+Q+C6p0DMRxMiv8AiSGZ+KWtITxw4ZbPPqHpmMlk1Fdf78XZxaWFtrbrePv2yBYmJcwjM5P/Il2aYpsCauP3BChRN0w0DANN00Tg3BeCnunb0NCgqq9vUHNLyse3Vu5A27Yf6HpiCqOtYZg8RCwutPSPsTVXccPBmMo4gNKyMJ1Oo+s6aNsWOo6NyWQSU6mUunjxklq+4nUsnlP2uKkpgNns0MnR0fvMX0GdcWPGYFL8/Wuon8bL2368HW9nU+rS5Svqm2/3IXmso6MTYzfa1cVLl9Xu3XtU+YKlOPOVOWPV1ccxnU7dbQ4GJgvBmT/QlgSamArqV3uPjvGEMZZ0XbWzahcdUouXrFArVr6hXlu2SpXOX0TfxktKFz4+Wn0MHcf5xzSN8mTSJXKTmKYZNMtFUvig620JDy0pMd7fj6tWv1Ugj82ZW+bNKi71ZhfP9xYuWlao3FaFjY0BnxmBpdMp5jhOkZQW8zdtWnImBRTZElhfwpzKAeoA+KOWlmt4+nQNkmkPHz6CtWfOYiTSipZlPchlsyebmwOTiRmBua7r68FsW7CzZ68zWo5UPvWBc2C2LaeNjAxXDefz52gCcrlscHg4X0PWIDVJAMqlMokZgdH2/xceF02SwJ+/igAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "TuringTrader.com, Quick-Change: A mean-reversion strategy on individual stocks",
                "title": "TuringTrader.com, Quick-Change: A mean-reversion strategy on individual stocks",
                "src": "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png",
                "srcSet": ["/static/2b23f891a5d398e021f35a8c1073fc00/5a46d/logo-512x512.png 300w", "/static/2b23f891a5d398e021f35a8c1073fc00/01e7c/logo-512x512.png 512w"],
                "sizes": "(max-width: 512px) 100vw, 512px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <p><strong parentName="p">{` Learn More `}</strong></p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/articles/momentum-mean-reversion/"
            }}>{`Momentum and mean-reversion`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/dashboard/market-vane/"
            }}>{`Market Vane signal`}</a></li>
        </ul>
      </Aside>
      <p><em parentName="p">{`Quick-Change`}</em>{` is a proprietary development inspired by prior art from `}<em parentName="p">{`Connors Research`}</em>{` and `}<em parentName="p">{`Laurens Bensdorp`}</em>{`. The strategy's operation can be summarized as follows:`}</p>
      <ul>
        <li parentName="ul">{`trade individual stocks from the S&P 100`}</li>
        <li parentName="ul">{`determine market regime based on the S&P 500's 52-week percentage range`}</li>
        <li parentName="ul">{`entry on a weekly schedule`}
          <ul parentName="li">
            <li parentName="ul">{`only enter new positions, while the market regime signals bullish conditions`}</li>
            <li parentName="ul">{`disqualify stocks with higher-than-average volatility`}</li>
            <li parentName="ul">{`open positions when RSI signals oversold conditions and 5-week returns are negative`}</li>
            <li parentName="ul">{`hold up to 6 positions simultaneously`}</li>
            <li parentName="ul">{`size position to fixed-fraction risk`}</li>
          </ul>
        </li>
        <li parentName="ul">{`exit on a daily schedule`}
          <ul parentName="li">
            <li parentName="ul">{`when hitting the trailing stop or missing the performance target`}</li>
            <li parentName="ul">{`shrink position size as required to keep fixed-fraction risk parameters`}</li>
          </ul>
        </li>
        <li parentName="ul">{`risk-off investment in `}<a parentName="li" {...{
            "href": "/portfolios/tt-buoy"
          }}>{`Buoy`}</a>{` strategy`}
          <ul parentName="li">
            <li parentName="ul">{`when the market regime indicates bearish conditions and no mean-reversion positions are held`}</li>
          </ul>
        </li>
      </ul>
      <p>{`Typical mean-reversion strategies often suffer from unfortunate exits. `}<em parentName="p">{`Quick-Change`}</em>{` uses a unique approach, closing positions when hitting a tight trailing stop or underperforming its continually adjusted profit targets. This approach helps close unprofitable positions early but, at the same time, lets winners run.`}</p>
      <p><em parentName="p">{`Quick-Change`}</em>{` only opens new positions on a weekly schedule. However, exit conditions and position-sizing are evaluated daily. So while most of the time, the strategy's asset allocation won't change throughout the week, investors should be prepared to adjust their holdings when the strategy calls to do so.`}</p>
      <h2>{`Diversification`}</h2>
      <p><em parentName="p">{`Quick-Change`}</em>{` invests in up to six stocks simultaneously. As a result, the strategy limits its concentration risk. However, during bullish market conditions, the strategy may allocate 100% of its capital to the U.S. stock market, even if only for brief periods.`}</p>
      <p>{`The combination of tight trailing stops, fixed-fraction position sizing and serial diversification with a bond-rotation strategy result in characteristics very similar to broadly diversified portfolios. The strategy's low beta of ~0.35 is most notable here.`}</p>
      <h2>{`Returns & Volatility`}</h2>
      <p>{`The tracking chart illustrates how `}<em parentName="p">{`Quick-Change`}</em>{` slowly but continually gains over its benchmark. But, in recessions, it quickly enters its risk-off allocation and avoids deep downturns.`}</p>
      <p>{`The strategy is out of the market most of the time and aims to achieve outsized returns when buying short-term dips. Combined with the fixed-fraction position-sizing, this mechanism leads to significantly lower volatility than buying and holding stocks.`}</p>
      <p>{`The Monte-Carlo simulation shows how `}<em parentName="p">{`Quick-Change`}</em>{`'s pessimistic return expectations turn positive after less than two years and outperform the benchmark across all periods. Further, the Monte-Carlo simulation suggests significantly lower drawdowns and recovery from drawdowns within about three years.`}</p>
      <h2>{`Account & Tax Considerations`}</h2>
      <p><em parentName="p">{`Quick-Change`}</em>{` trades frequently and regularly triggers taxable events. The portfolio rarely holds assets long enough to qualify for long-term treatment of capital gains.`}</p>
      <p><em parentName="p">{`Quick-Change`}</em>{` invests in up to six high-flying and potentially expensive stocks. Further, it requires accurate position sizing to maintain its low risk profile. As a result, the strategy should be funded with no less than $30,000.`}</p>
    </Review>
    <Notes mdxType="Notes">
      <p><strong parentName="p">{`Portfolio Revisions`}</strong></p>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, July 2022`}</a>{`: Initial release.`}</li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "./?v=1"
          }}>{`v1, August 2022`}</a>{`: Minor edit capping the maximum position size for a single stock.`}</li>
      </ul>
    </Notes>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      